import React, { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { teal } from '@mui/material/colors';
import '@styles/App.css';
import '@fontsource/ibm-plex-sans';

const Provider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xxs: 0,
            xs: 350,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
          },
        },
        spacing: (factor) => `${0.5 * factor}rem`,
        typography: {
          fontFamily: 'IBM Plex Sans',
          h4: {
            fontSize: 'max(1.25rem, 20px)',
            lineHeight: 'max(1.25rem, 28px)',
            fontWeight: 'bold',
          },
          h5: {
            fontSize: 'max(1.1rem, 16px) !important',
            lineHeight: 'max(1.1rem, 24px)',
          },
          text: {
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1rem',
          },
          textBold: {
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.15rem',
            lineHeight: '1.25rem',
          },
        },
        palette: {
          primary: {
            main: teal[500],
          },
          // secondary: {
          //   main: green[500],
          // },
          // mode: prefersDarkMode ? 'dark' : 'light',
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              root: ({ theme }) => ({
                // use JavaScript conditional expression
                background: theme.palette.mode === 'dark' ? '#121212' : '#fff',
              }),
            },
          },
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export { Provider };
