import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useAtom } from 'jotai';
import supabase from '@utils/supabase';
import {
  isOneSignalLoaded,
  isPushSubscribed,
  isPushEnabled,
  notificationScheduleAtom,
} from '@utils/store';
import useUser from './useUser';
import { Capacitor } from '@capacitor/core';
import { walkthroughAtom, walkthroughStepAtom } from 'utils/store';

const Provider = ({ children }) => {
  const { session } = useUser(); // Using the useUser hook to get the user session
  const [loadedOneSignal] = useAtom(isOneSignalLoaded); // State to track if OneSignal has been loaded
  const [, setPushSubscribed] = useAtom(isPushSubscribed); // State atom to store push subscription status
  const [, setPushEnabled] = useAtom(isPushEnabled); // State atom to store push notification enabled status
  const [, setNotificationSchedule] = useAtom(notificationScheduleAtom); // State atom to store the user's notification schedule

  useEffect(() => {
    // User's notification schedule
    const userNotifications =
      session?.user.user_metadata?.notification_schedule || null;

    // Function to fetch and update push notification status
    const notificationStatus = async () => {
      if (Capacitor.getPlatform() === 'web') {
        return;
      }
      const OneSignal = await import('onesignal-cordova-plugin').then(
        (x) => x.default,
      );

      try {
        // Fetching the device state from OneSignal
        await OneSignal.getDeviceState(async (deviceState) => {
          const { hasNotificationPermission, pushDisabled } = deviceState;
          setPushSubscribed(hasNotificationPermission); // Updating push subscription state
          setPushEnabled(!pushDisabled); // Updating push notification enabled state

          if (hasNotificationPermission) {
            // If userNotifications is not available, set a default value in Supabase and local state
            if (!userNotifications) {
              await supabase.auth.update({
                data: { notification_schedule: 'morning' },
              });
              await setNotificationSchedule('morning');
            } else {
              await setNotificationSchedule(userNotifications);
            }
          }
        });
      } catch (error) {
        throw error;
      }
      return;
    };

    // When the user session changes, fetch and update notification status
    if (session && loadedOneSignal) {
      notificationStatus();
    }
  }, [session, loadedOneSignal]);

  // Return the provider component
  return <>{children}</>;
};

// Asynchronous function to handle push notification subscription
const pushSubscribe = async (schedule) => {
  try {
    // Import the OneSignal Cordova plugin as the default export
    const OneSignal = await import('onesignal-cordova-plugin').then(
      (x) => x.default,
    );

    if (
      Capacitor.getPlatform() === 'ios' ||
      Capacitor.getPlatform() === 'android'
    ) {
      // Provide user consent for push notifications
      await OneSignal.provideUserConsent(true);

      await OneSignal.promptForPushNotificationsWithUserResponse(
        async (response) => {
          // Wait for the user's response
          const isAccepted = await response;

          // Check the user's response and update the notification schedule accordingly
          if (isAccepted) {
            const { error } = await supabase.auth.update({
              data: { notification_schedule: schedule },
            });

            if (error) {
              throw error;
            }
          } else {
            throw new Error('User declined notifications');
          }
        },
      );
    } else {
      // If the platform is not iOS or Android, throw an error
      throw new Error('Not a mobile device');
    }
  } catch (error) {
    // Handle errors by rethrowing the error
    throw error;
  }
  // Return the provided schedule after processing push notifications
  return schedule;
};

// Function to update the notification schedule in the supabase authentication data
const updateSchedule = async (schedule) => {
  import('onesignal-cordova-plugin')
    .then((x) => x.default)
    .then(async (OneSignal) => {
      if (
        Capacitor.getPlatform() === 'ios' ||
        Capacitor.getPlatform() === 'android'
      ) {
        OneSignal.disablePush(false);
        const { error } = await supabase.auth.update({
          data: { notification_schedule: schedule },
        });

        if (error) {
          throw error;
        }
      } else {
        throw new Error('Not a mobile device');
      }
    });

  return schedule;
};

// Function to enable/disable push notifications using the OneSignal plugin
const pushChange = async (checked) => {
  try {
    import('onesignal-cordova-plugin')
      .then((x) => x.default)
      .then((OneSignal) => {
        if (
          Capacitor.getPlatform() === 'ios' ||
          Capacitor.getPlatform() === 'android'
        ) {
          // Enabling or disabling push notifications based on the checked value
          OneSignal.disablePush(!checked);
        }
      });
  } catch (error) {
    console.error('Error disabling push', error);
    throw error;
  }

  return checked;
};

/**
 * This function is the main entry point for the useNotifications hook.
 *
 * @returns An object with the following properties:
 *   - pushSubscribe: A mutation that subscribes to push notifications.
 *   - pushChange: A mutation that changes whether push notifications are enabled.
 *   - loadedOneSignal: A boolean that indicates whether OneSignal has been initialized.
 *   - pushSubscribed: A boolean that indicates whether the user is subscribed to push notifications.
 *   - pushEnabled: A boolean that indicates whether push notifications are enabled.
 *   - notificationSchedule: The user's notification schedule.
 */
const useNotifications = () => {
  const [loadedOneSignal, _] = useAtom(isOneSignalLoaded); // State to track if OneSignal has been loaded
  const [pushSubscribed, setPushSubscribed] = useAtom(isPushSubscribed); // State atom to store push subscription status
  const [pushEnabled, setPushEnabled] = useAtom(isPushEnabled); // State atom to store push notification enabled status
  const [walkthroughIsOpen, setWalkthroughIsOpen] = useAtom(walkthroughAtom);
  const [, setWalkthroughStep] = useAtom(walkthroughStepAtom);
  const [notificationSchedule, setNotificationSchedule] = useAtom(
    notificationScheduleAtom,
  );
  // Return the mutations and states related to push notifications
  return {
    pushSubscribe: useMutation((schedule) => pushSubscribe(schedule), {
      onSuccess: (data) => {
        if (walkthroughIsOpen) {
          setWalkthroughIsOpen(false);
          setWalkthroughStep(0);
        }
        setNotificationSchedule(data);
        setPushEnabled(true);
        setPushSubscribed(true);
      },
    }),
    updateSchedule: useMutation((schedule) => updateSchedule(schedule), {
      onSuccess: (data) => {
        if (walkthroughIsOpen) {
          setWalkthroughIsOpen(false);
          setWalkthroughStep(0);
        }
        setNotificationSchedule(data);
        setPushEnabled(true);
      },
    }),

    pushChange: useMutation((checked) => pushChange(checked), {
      onSuccess: (checked) => {
        setPushEnabled(checked);
      },
    }),
    loadedOneSignal,
    pushSubscribed,
    pushEnabled,
    notificationSchedule,
  };
};

export { Provider };
export default useNotifications;
