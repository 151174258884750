import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { getRandomColor } from './colors';
import dayjs from 'dayjs';

export const invited = atom(0);
export const invites = atom(3);
export const loading = atom(true);
export const calendarDays = atomWithStorage('calendarDays', []);
export const posts = atom([]);
export const postsOpened = atom([]);
export const bookmarkedPosts = atom([]);
export const trending = atom([]);
export const history = atom([]);
export const topicPosts = atom([]);
export const topicArticles = atom([]);
export const newsFeed = atom([]);
export const newsFeedDay = atom(new Date());
export const newsFeedIds = atom([]);
export const appVersion = atom('1.5.7');
export const postModal = atom(false);
export const dataUsedModal = atom(false);
export const dataNotUsedModal = atom(false);

export const topicsSelected = atomWithStorage('topicsSelected', []);
export const topics = atomWithStorage('topics', []);
export const bookmarks = atomWithStorage('bookmarks', []);
export const bgColor = atomWithStorage('bgColor', getRandomColor());

// AB Testing
export const abTestExperiments = atomWithStorage('abTestExperiments', []);

// Tours
export const tours = atomWithStorage('tours', []);
export const pathTours = atom([]);
export const tourSteps = atom([]);
export const completedTours = atomWithStorage('completedTours', []);
export const hiddenTours = atom([]);

//Explore page

export const exploreInitialLoader = atom(true);
export const exploreArticles = atom([]);
export const exploreThemes = atom([]);
export const explorePagination = atom(1);
export const exploreCurrentFilters = atom({
  theme: null,
  date: null,
  keywords: null,
});
export const exploreSearchBar = atom('');
export const exploreSelectedDate = atom(dayjs());
export const exploreThemesBar = atom(0);

// Push Modal
export const pushModal = atom(false);
export const pushModalTriggered = atom(false);
export const appModal = atomWithStorage('appModal', false);

// Walkthrough
export const walkthroughAtom = atomWithStorage('walkthrough', true);
export const walkthroughStepAtom = atom(0);

// Push Notifications
export const isOneSignalLoaded = atom(false);
export const isPushSubscribed = atom(false);
export const isPushEnabled = atomWithStorage('isPushEnabled', false);
export const notificationScheduleAtom = atom(null);
export const hasRequestedPushPermissions = atomWithStorage(
  'hasRequestedPushPermissions',
  false,
);
export const lastDateRequestedPushPermissions = atomWithStorage(
  'lastDateRequestedPushPermissions',
  null,
);
