const COLORS = [
  '#7BD2DB',
  '#35B6BD',
  '#4BC2A1',
  '#F9BC07',
  '#F3581A',
  '#EE1A24',
  '#EC0969',
  '#841B81',
  '#007879',
];

export const getRandomColor = () =>
  COLORS[Math.floor(Math.random() * COLORS.length)];

// Get color from index
export const getColorFromIndex = (index) => {
  const indexColors = ['#327678', '#DA326A', '#F1BD42', '#5DB3BB', '#E26232'];
  return indexColors[index % indexColors.length];
};
// Get color from type
export function politicalColor(type) {
  switch (type) {
    case 'left':
      return '#916E08';
    case 'center':
      return '#366163';
    case 'right':
      return '#913908';
    default:
      return '#000000';
  }
}
export function politicalBackground(type) {
  switch (type) {
    case 'left':
      return '#FADE91';
    case 'center':
      return '#B3EBEF';
    case 'right':
      return '#FFC29F';
    default:
      return '#000000';
  }
}

export const browserColors = () => {
  const availableColors = [
    '#F9BC07',
    '#F3581A',
    '#EE1A24',
    '#EC0969',
    '#841B81',
  ];
  return availableColors[Math.floor(Math.random() * availableColors.length)];
};

export const logoBackground = () => '#FFAA41';

export const graphColors = () => ['#7BD2DB', '#e3e3e3', '#007879'];
export const graphColor = () => ['#009688'];
