import React, { useContext, useEffect, useState } from 'react';
import supabase from '@utils/supabase';
import { navigate } from 'gatsby';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import {
  tours as toursState,
  pathTours as pathToursState,
  hiddenTours as hiddenToursState,
  completedTours as completedToursState,
  tourSteps as tourStepsState,
} from '@utils/store';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import jsonLogic from 'json-logic-js';
import useUser from '@hooks/useUser';
import { event } from 'utils/analytics';
import '@styles/Sheperd.css';

const Provider = ({ children }) => {
  const [, setTours] = useAtom(toursState);
  const [steps] = useAtom(tourStepsState);
  const { meta_data } = useUser();

  useEffect(() => {
    const getUserData = async () => {
      let deviceData = {};
      try {
        deviceData = await Device.getInfo();
      } catch (error) {
        console.error(error);
      }
      let languageCode = null;
      try {
        languageCode = await Device.getLanguageCode();
      } catch (error) {
        console.error(error);
      }
      let languageTag = null;
      try {
        languageTag = await Device.getLanguageTag();
      } catch (error) {
        console.error(error);
      }
      return {
        languageCode,
        languageTag,
        ...deviceData,
        ...meta_data,
      };
    };
    const loadTours = async () => {
      let { data, error } = await supabase
        .from('config_tours')
        .select('*')
        .eq('active', true);

      if (error) {
        throw error;
      }

      const userData = await getUserData();
      const activeTours = data
        .map((tour) => {
          const { audience } = tour;
          if (audience) {
            const userIsInAudience = jsonLogic.apply(audience, userData);
            if (!userIsInAudience) return null;
          }
          return tour;
        })
        .filter((a) => a);
      setTours(activeTours);
    };

    try {
      loadTours();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <ShepherdTour
      steps={steps}
      tourOptions={{
        defaultStepOptions: {
          scrollTo: { behavior: 'smooth', block: 'center' },
          cancelIcon: {
            enabled: true,
          },
        },
        useModalOverlay: true,
      }}
    >
      {children}
    </ShepherdTour>
  );
};

export default function useTours({ path } = {}, prefixTranslation = '') {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: prefixTranslation,
  });
  const tour = useContext(ShepherdTourContext);
  const [tours] = useAtom(toursState);
  const [, setPathTours] = useAtom(pathToursState);
  const [hiddenTours, setHiddenTours] = useAtom(hiddenToursState);
  const [completedTours, setCompletedTours] = useAtom(completedToursState);
  const [steps, setSteps] = useAtom(tourStepsState);
  const [isTourActive, setIsTourActive] = useState(false);
  const [activeTour, setActiveTour] = useState(null);

  useEffect(() => {
    const hasPathTours = tours
      .map((tour) => {
        if (
          tour.path !== path ||
          completedTours.indexOf(tour.name) !== -1 ||
          hiddenTours.indexOf(tour.name) !== -1
        )
          return null;
        return { ...tour };
      })
      .filter((a) => a);
    if (hasPathTours && hasPathTours.length) {
      setPathTours(hasPathTours);
      const currentTour = hasPathTours[0];
      const steps = currentTour.steps.map((step, index) => {
        const { action } = step;
        let buttons = [];
        let buttonText = index < currentTour.steps.length - 1 ? 'Next' : 'Done';
        if (index) {
          buttons.push({
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          });
        }
        if (!action) {
          buttons.push({
            classes: 'shepherd-button-primary',
            text: buttonText,
            type: 'next',
          });
        } else {
          if (action.button) {
            buttonText = i18n.exists(action.button)
              ? t(action.button)
              : action.button;
          }
          buttons.push({
            classes: 'shepherd-button-primary',
            text: buttonText,
            type: '',
            async action() {
              switch (action.type) {
                case 'navigate': {
                  if (action.path) {
                    navigate(action.path);
                    event('Tour Action', {
                      name: currentTour.name,
                      action: 'navigate',
                    });
                  }
                  break;
                }
                case 'share':
                  try {
                    await Share.share({
                      title: action.title,
                      text: action.text,
                      url: action.url,
                    });
                    event('Tour Action', {
                      name: currentTour.name,
                      action: 'share',
                    });
                  } catch (error) {
                    // Share does not work on web
                  }
                  break;
                default:
                  break;
              }
              if (index === currentTour.steps.length - 1) {
                this.next();
              } else {
                this.hide();
              }
            },
          });
        }
        return { ...step, buttons };
      });
      setSteps(steps);
      setActiveTour(currentTour);
    }
  }, [path, isTourActive]);

  useEffect(() => {
    if (steps && steps.length && !isTourActive && activeTour) {
      setIsTourActive(true);
      tour.start();
      tour.on('start', () => {
        event('Tour Start', { name: activeTour.name });
      });
      tour.on('complete', () => {
        event('Tour Complete', { name: activeTour.name });
        setCompletedTours([...completedTours, activeTour.name]);
        setIsTourActive(false);
      });
      tour.on('cancel', () => {
        event('Tour Cancel', { name: activeTour.name });
        if (!activeTour.mandatory) {
          setCompletedTours([...completedTours, activeTour.name]);
        } else {
          setHiddenTours([...hiddenTours, activeTour.name]);
        }
        setIsTourActive(false);
      });
      tour.on('hide', () => {
        event('Tour Hide', { name: activeTour.name });
        if (!activeTour.mandatory) {
          setCompletedTours([...completedTours, activeTour.name]);
        } else {
          setHiddenTours([...hiddenTours, activeTour.name]);
        }
        setIsTourActive(false);
      });
    }
  }, [steps, activeTour]);

  return null;
}

export { Provider };
