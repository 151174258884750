import { useMutation } from 'react-query';
import { useAtom } from 'jotai';
import supabase from '@utils/supabase';
import {
  topics as topicsState,
  topicsSelected as topicsSelectedState,
} from '@utils/store';

// Function to fetch topics from the 'topic' table in Supabase
const fetch = async () => {
  let { data, error } = await supabase.from('topic').select('*');

  if (error) {
    throw error;
  }

  return data;
};

// Function to add a single topic to the user's topics list
const add = async (topic) => {
  const topics = [...userTopics(), { id: topic, type: 'added' }];
  const { data, error } = await supabase.auth.update({ data: { topics } });

  if (error) {
    throw error;
  }

  return data;
};

// Function to add multiple topics to the user's topics list
const addMultiple = async (topics) => {
  const newTopics = topics.map((id) => ({ id, type: 'added' }));
  const { data, error } = await supabase.auth.update({
    data: { topics: [...userTopics(), ...newTopics] },
  });

  if (error) {
    throw error;
  }

  return data;
};

// Function to remove a topic from the user's topics list
const remove = async (topic) => {
  const topics = userTopics().filter((t) => t.id !== topic);
  const { data, error } = await supabase.auth.update({ data: { topics } });

  if (error) {
    throw error;
  }

  return data;
};

// Function to get the user's topics from the user_metadata
const userTopics = () => supabase.auth.user()?.user_metadata?.topics || [];

// Function to get the user's selected topics from the provided topics list
const userSelectedTopics = (topics) =>
  topics
    .filter((topic) => Boolean(userTopics().some((t) => t.id == topic.id)))
    .map((t) => t.id);

/**
 * This function is the main entry point for the useTopics hook.
 *
 * @returns An object with the following properties:
 *   - fetch: A mutation that fetches topics from the 'topic' table.
 *   - add: A mutation that adds a topic to the user's topics list.
 *   - addMultiple: A mutation that adds multiple topics to the user's topics list.
 *   - remove: A mutation that removes a topic from the user's topics list.
 *   - topics: The current state of topics.
 *   - selected: The current state of selected topics.
 *   - userTopics: The current user's topics.
 */
export default function useTopics() {
  const [topics, setTopics] = useAtom(topicsState); // State atom to store the topics
  const [selected, setSelected] = useAtom(topicsSelectedState); // State atom to store the selected topics

  return {
    // Mutation for fetching topics from the 'topic' table
    fetch: useMutation(() => fetch(), {
      onSuccess: (data) => {
        setTopics(data); // Update the topics state with fetched data
        if (userTopics().length) {
          setSelected(userSelectedTopics(data)); // If user has topics, update the selected state with user's selected topics
        }
      },
    }),
    // Mutation for adding a topic to the user's topics list
    add: useMutation((topic) => add(topic), {
      onSuccess: () => {
        setSelected([...selected, ...userSelectedTopics(topics)]); // Update the selected state with the added topic
      },
    }),
    // Mutation for adding multiple topics to the user's topics list
    addMultiple: useMutation((topics) => addMultiple(topics), {
      onSuccess: () => {
        setSelected([...selected, ...userSelectedTopics(topics)]); // Update the selected state with the added topics
      },
    }),
    // Mutation for removing a topic from the user's topics list
    remove: useMutation((topic) => remove(topic), {
      onSuccess: () => {
        setSelected([...selected, ...userSelectedTopics(topics)]); // Update the selected state after removing the topic
      },
    }),
    topics, // Current topics state
    selected, // Current selected topics state
    userTopics: userTopics(), // Current user's topics
  };
}
