import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useAtom } from 'jotai';
import { isOneSignalLoaded } from '@utils/store';

const Provider = ({ children }) => {
  const [, setLoadedOneSignal] = useAtom(isOneSignalLoaded); // State to track if OneSignal has been loaded

  useEffect(() => {
    import('onesignal-cordova-plugin')
      .then((x) => x.default)
      .then((OneSignal) => {
        try {
          // Check if the platform is iOS and set user privacy consent
          if (Capacitor.getPlatform() === 'ios') {
            OneSignal.setRequiresUserPrivacyConsent(true);
          }
          if (Capacitor.getPlatform() !== 'web') {
            OneSignal.setAppId(process.env.GATSBY_ONESIGNAL_APP);
          }

          if (
            Capacitor.getPlatform() === 'ios' ||
            Capacitor.getPlatform() === 'android'
          ) {
            setLoadedOneSignal(true);
          }
        } catch (error) {
          console.error('Error initializing OneSignal', error);
        }
      });
  }, []);

  return <>{children}</>;
};

export { Provider };
