import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Capacitor } from '@capacitor/core';
import { useAtom } from 'jotai';
import { navigate } from 'gatsby';
import supabase from '@utils/supabase';
import {
  loading as loadingState,
  newsFeed as newsFeedState,
  topicsSelected as topicsSelectedState,
  invited as invitedState,
  invites as invitesState,
} from '@utils/store';

const LOADING_SECONDS = 2; // TODO: review this solution once supabase has a ready state or promises support for initialization (https://github.com/supabase/supabase-js/issues/181)

const signUp = async ({ email, password, name, code }) => {
  const { data, error } = await supabase.auth.signUp(
    { email, password },
    {
      data: {
        name,
        code,
      },
    },
  );

  if (error) {
    throw error;
  }

  return data;
};

const signIn = async ({ email, password }) => {
  const { user, error } = await supabase.auth.signIn({ email, password });

  if (error) {
    throw error;
  }

  return user;
};

const loginGoogle = async () => {
  const { user, error } = await supabase.auth.signIn(
    {
      provider: 'google',
    },
    {
      redirectTo:
        Capacitor.getPlatform() === 'web'
          ? ''
          : 'app.thenewsroom.ai://login-callback/',
    },
  );

  if (error) {
    throw error;
  }

  return user;
};

const loginApple = async () => {
  const { user, error } = await supabase.auth.signIn(
    {
      provider: 'apple',
    },
    {
      redirectTo:
        Capacitor.getPlatform() === 'web'
          ? ''
          : 'app.thenewsroom.ai://login-callback/',
    },
  );

  if (error) {
    throw error;
  }

  return user;
};

const signOut = async () => {
  const { error } = await supabase.auth.signOut();

  if (error) {
    throw error;
  }
};

const forgotPassword = async ({ email }) => {
  const { data, error } = await supabase.auth.api.resetPasswordForEmail(
    email.trim(),
    {
      redirectTo: 'https://app.thenewsroom.ai/reset-password',
    },
  );

  if (error) {
    throw error;
  }

  return data;
};

const resetPassword = async ({ accessToken, password }) => {
  const { data, error } = await supabase.auth.api.updateUser(accessToken, {
    password,
  });

  if (error) {
    throw error;
  }

  return data;
};

const changePassword = async ({ password }) => {
  const { data, error } = await supabase.auth.update({ password });

  if (error) {
    throw error;
  }

  return data;
};

const updatePicture = async (imageBuffer) => {
  const { id } = supabase.auth.user();
  const { data, error } = await supabase.storage
    .from('users')
    .upload(`${id}.png`, imageBuffer, {
      upsert: true,
    });

  if (error) {
    throw error;
  }

  const picture = `https://bnbfzyocogigcosxjqlb.supabase.in/storage/v1/object/public/${data.Key}`;

  const { dataUser, errorUser } = await supabase.auth.update({
    data: { picture },
  });

  if (errorUser) {
    throw errorUser;
  }

  return dataUser;
};

const updateUserTimeZone = async () => {
  const userTimeZone = supabase.auth.user()?.user_metadata.user_time_zone;
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (userTimeZone === currentTimeZone) return;

  const { error } = await supabase.auth.update({
    data: { user_time_zone: currentTimeZone },
  });

  if (error) {
    throw error;
  }

  return;
};

const updateOneSignalId = async (oneSignalId) => {
  const { data, error } = await supabase.auth.update({
    data: { oneSignalId },
  });

  if (error) {
    throw error;
  }

  return data;
};

const updateInviteCode = async (code) => {
  const { data, error } = await supabase.auth.update({
    data: { code },
  });

  if (error) {
    throw error;
  }

  return data;
};

const populateTopics = async (selectedTopics) => {
  if (!selectedTopics.length) return;
  const topics = selectedTopics.map((topic) => ({ id: topic, type: 'added' }));
  const { data, error } = await supabase.auth.update({ data: { topics } });

  if (error) {
    throw error;
  }

  return data;
};

const checkAuth = () => {
  if (!supabase.auth.session()) {
    navigate(`/sign-in?backTo=${window.location.pathname}`);
  }
};

const redirect = () => {
  const urlParams = new URL(document.location).searchParams;
  navigate(urlParams.get('backTo') || '/');
};

const getInvited = async () => {
  const code = supabase.auth.user()?.user_metadata.invite_code;
  const { count, error } = await supabase
    .from('invite_codes')
    .select('*', { count: 'exact' })
    .eq('signup_code', code);

  if (error) {
    throw error;
  }

  return count;
};

const getInvites = async () => {
  const { data, error } = await supabase
    .from('invite_codes')
    .select('*')
    .eq('uuid', supabase.auth.user()?.id);

  if (error) {
    throw error;
  }

  if (data && data.length && data[0].number_of_invites) {
    return data[0].number_of_invites;
  }

  return 3;
};

const deleteAccount = async () => {
  const { data, error } = await supabase
    .from('delete_account')
    .insert({ email: supabase.auth.user()?.email }, { returning: 'minimal' });

  if (error) {
    throw error;
  }

  return data;
};

export default function useUser() {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useAtom(loadingState);
  const [invited, setInvited] = useAtom(invitedState);
  const [invites, setInvites] = useAtom(invitesState);
  const [_, setNewsFeed] = useAtom(newsFeedState);
  const [topicsSelected] = useAtom(topicsSelectedState);

  useEffect(() => {
    const loading = setTimeout(() => {
      setLoading(false);
    }, LOADING_SECONDS * 1000);
    return () => clearTimeout(loading);
  }, []);

  return {
    signUp: useMutation(
      ({ email, password, name, code }) =>
        signUp({ email, password, name, code }),
      {
        onSuccess: async () => {
          await populateTopics(topicsSelected);
          redirect();
        },
      },
    ),
    signIn: useMutation(({ email, password }) => signIn({ email, password }), {
      onSuccess: redirect,
    }),
    loginGoogle: useMutation(() => loginGoogle(), {
      onSuccess: redirect,
    }),
    loginApple: useMutation(() => loginApple(), {
      onSuccess: redirect,
    }),
    signOut: useMutation(() => signOut(), {
      onSuccess: () => {
        queryClient.removeQueries();
        setNewsFeed([]);
        redirect();
      },
    }),
    getInvited: useMutation(() => getInvited(), {
      onSuccess: (count) => {
        setInvited(count);
      },
    }),
    getInvites: useMutation(() => getInvites(), {
      onSuccess: (number) => {
        setInvites(number);
      },
    }),
    deleteAccount: useMutation(() => deleteAccount(), {
      onSuccess: () => {
        signOut();
        queryClient.removeQueries();
        setNewsFeed([]);
        redirect();
      },
    }),
    changePassword: useMutation(({ password }) => changePassword({ password })),
    forgotPassword: useMutation(({ email }) => forgotPassword({ email })),
    resetPassword: useMutation(({ accessToken, password }) =>
      resetPassword({ accessToken, password }),
    ),
    updatePicture: useMutation((imageBuffer) => updatePicture(imageBuffer)),
    updateUserTimeZone: useMutation(() => updateUserTimeZone()),
    updateOneSignalId: useMutation((oneSignalId) =>
      updateOneSignalId(oneSignalId),
    ),
    updateInviteCode: useMutation((code) => updateInviteCode(code)),
    user: supabase.auth.user(),
    id: supabase.auth.user()?.id,
    meta_data: supabase.auth.user()?.user_metadata,
    name: supabase.auth.user()?.user_metadata.name,
    picture: supabase.auth.user()?.user_metadata.picture,
    code: supabase.auth.user()?.user_metadata.invite_code,
    code_used: supabase.auth.user()?.user_metadata.code,
    invited,
    invites,
    session: supabase.auth.session(),
    oneSignalId: supabase.auth.user()?.user_metadata.oneSignalId,
    checkAuth,
    loading,
  };
}
