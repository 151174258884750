import React, { useEffect } from 'react';
import supabase from '@utils/supabase';
import useUser from 'hooks/useUser';
import useTopics from 'hooks/useTopics';
import { identify } from '@utils/analytics';

const Provider = ({ children }) => {
  const { updateOneSignalId } = useUser();
  const { topics, userTopics } = useTopics();

  useEffect(() => {
    let user = supabase.auth.user();
    if (user) {
      updateUserData(user);
    } else {
      supabase.auth.onAuthStateChange((event, session) => {
        if (event == 'SIGNED_IN') {
          ({ user } = session);
          updateUserData(user);
        }
      });
    }
  }, []);

  const updateUserData = (user) => {
    const tags = {
      bookmarks: user.user_metadata?.bookmarks,
    };
    topics.map((topic) => {
      const name = topic.titles.en;
      const hasTopic = userTopics.find((t) => t.id === topic.id);
      tags[name] = hasTopic ? hasTopic.type : null;
    });
    identify(user.id, tags);
    try {
      import('onesignal-cordova-plugin')
        .then((x) => x.default)
        .then((OneSignal) => {
          try {
            OneSignal.setExternalUserId(user.id);
            OneSignal.getDeviceState(({ userId }) => {
              if (userId) {
                updateOneSignalId.mutate(userId);
              }
            });
            OneSignal.sendTags(tags);
          } catch (error) {}
        });
    } catch (error) {}
  };

  return <>{children}</>;
};

export { Provider };
