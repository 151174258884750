import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { enUS } from './locales';

export let language = 'en';

const bundledResources = {
  en: enUS,
};

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init(
    {
      defaultLocale: 'en',
      locales: ['en'],
      fallbackLng: 'en',
      keySeparator: '.',
      interpolation: {
        escapeValue: true,
      },
      react: {
        useSuspense: false,
      },
      backend: {
        backends: [resourcesToBackend(bundledResources), HttpBackend],
        backendOptions: [
          {
            loadPath:
              'https://bnbfzyocogigcosxjqlb.supabase.co/storage/v1/object/public/config/i18n/locales/{{lng}}.json',
          },
        ],
      },
    },
    () => {
      language = i18n.resolvedLanguage;
    },
  );

export default i18n;
