import React, { useEffect, useState } from 'react';
import supabase from '@utils/supabase';
import { Device } from '@capacitor/device';
import { emitter } from '@marvelapp/react-ab-test';
import { abTestExperiments } from '@utils/store';
import { experimentPlay, experimentVariant } from '@utils/analytics';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import jsonLogic from 'json-logic-js';
import useUser from '@hooks/useUser';

// Provider component for handling A/B testing and setting experiments
const Provider = ({ children }) => {
  const { id, meta_data } = useUser();
  const [, setExperiments] = useAtom(abTestExperiments);

  useEffect(() => {
    // Function to get the user's ID from Supabase or Device plugin
    const getUserId = async () => {
      return id || (await Device.getId()).uuid;
    };

    // Function to get user data, including device info and metadata
    const getUserData = async () => {
      let deviceData = {};
      try {
        deviceData = await Device.getInfo();
      } catch (error) {
        console.error(error);
      }
      let languageCode = null;
      try {
        languageCode = await Device.getLanguageCode();
      } catch (error) {
        console.error(error);
      }
      let languageTag = null;
      try {
        languageTag = await Device.getLanguageTag();
      } catch (error) {
        console.error(error);
      }
      return {
        languageCode,
        languageTag,
        ...deviceData,
        ...meta_data,
      };
    };

    // Function to update the active variants of experiments in Supabase
    const updateExperimentsVariants = async (experiments) => {
      const current = supabase.auth.user()?.user_metadata?.experiments || {};
      experiments.map((experiment) => {
        const variant = emitter.getActiveVariant(experiment.name);
        current[experiment.name] = variant;
        experimentVariant(experiment.name, variant);
      });
      await supabase.auth.update({
        data: { experiments: current },
      });
    };

    // Function to load active experiments from Supabase and define variants
    const loadExperiments = async () => {
      let { data, error } = await supabase
        .from('config_ab_testing')
        .select('*')
        .eq('active', true);

      if (error) {
        throw error;
      }

      const userId = await getUserId();
      const userData = await getUserData();
      const activeExperiments = data
        .map((experiment) => {
          const { audience } = experiment;
          if (audience) {
            const userIsInAudience = jsonLogic.apply(audience, userData);
            if (!userIsInAudience) return null;
          }

          const { name, variants, weights, defaultVariant } = experiment;
          emitter.defineVariants(name, variants, weights);
          emitter.calculateActiveVariant(name, userId, defaultVariant);
          return experiment;
        })
        .filter((a) => a);

      setExperiments(activeExperiments);
      updateExperimentsVariants(activeExperiments);
    };

    try {
      loadExperiments();
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Render the children components wrapped inside the A/B testing provider
  return <>{children}</>;
};

// Custom hook for handling A/B testing and translations
export default function useABTesting(
  { path } = {},
  prefixTranslation, // This is for adding a prefix to the translation key
) {
  // Use the translation hook from react-i18next to access translations
  const { t, i18n } = useTranslation(
    'translation', // namespace
    { keyPrefix: prefixTranslation }, // options
  );

  // Get the list of active experiments from the store using Jotai's useAtom
  const [experiments] = useAtom(abTestExperiments);
  const [pathExperiments, setPathExperiments] = useState([]);

  useEffect(() => {
    // Update path experiments when the 'path' prop changes
    const hasPathExperiments = experiments
      .map((experiment) => {
        if (experiment.path !== path) return null;
        const activeVariant = emitter.getActiveVariant(experiment.name);
        if (!activeVariant) return null;
        experimentPlay(experiment.name, activeVariant);
        return { ...experiment, value: activeVariant };
      })
      .filter((a) => a);

    // Set the path experiments in the component's state
    if (hasPathExperiments && hasPathExperiments.length) {
      setPathExperiments(hasPathExperiments);
    }
  }, [path]);

  // Function to translate using A/B test variants if available
  const abT = (jsonPath) => {
    if (!pathExperiments.length) return t(jsonPath);
    let string = '';
    pathExperiments.map((experiment) => {
      if (!string) {
        const { type, component } = experiment;
        if (type === 'copy' && component === jsonPath) {
          const { value } = experiment;
          string = i18n.exists(value) ? t(value) : value;
        }
      }
    });

    return string || t(jsonPath);
  };

  // Return the translated function and a list of features for the current path
  return {
    t: abT,
    features: pathExperiments.filter((e) => e.type === 'feature'),
  };
}

export { Provider };
