import mixpanel from 'mixpanel-browser';

export const identify = (userId, userData = {}) => {
  mixpanel.identify(userId);
  mixpanel.people.set(userData);
};

export const event = (event, data = {}) => {
  mixpanel.track(event, data);
};

export const experimentVariant = (name, variant) => {
  mixpanel.track('Experiment Variant', { name, variant });
};

export const experimentPlay = (name, variant) => {
  mixpanel.track('Experiment Play', { name, variant });
};

export const init = () => {
  mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN);
};
