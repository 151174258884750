import '@utils/i18n';
import React from 'react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Provider as QueryProvider } from '@hooks/useQuery';
import { Provider as ThemeProvider } from '@hooks/useTheme';
import { Provider as OneSignalProvider } from '@hooks/useOneSignal';
import { Provider as AuthProvider } from '@hooks/useAuth';
import { Provider as ABTestingProvider } from '@hooks/useABTesting';
import { Provider as ToursProvider } from '@hooks/useTours';
import { Provider as NotificationProvider } from 'hooks/useNotifications';
import { init } from 'utils/analytics';

export const onClientEntry = async () => {
  if (Capacitor.isPluginAvailable('StatusBar')) {
    await StatusBar.setStyle({ style: Style.Light });
    if (Capacitor.getPlatform() === 'android') {
      await StatusBar.setBackgroundColor({ color: '#ffffff' });
      await StatusBar.setOverlaysWebView({ overlay: false });
    }
  }
};

export const wrapRootElement = ({ element }) => {
  init();
  App.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
      App.exitApp();
    } else {
      window.history.back();
    }
  });

  return (
    <QueryProvider>
      <ThemeProvider>
        <OneSignalProvider>
          <AuthProvider>
            <ABTestingProvider>
              <ToursProvider>
                <NotificationProvider>{element}</NotificationProvider>
              </ToursProvider>
            </ABTestingProvider>
          </AuthProvider>
        </OneSignalProvider>
      </ThemeProvider>
    </QueryProvider>
  );
};

defineCustomElements(window);
